.container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 4rem;
  position: relative;
  overflow: hidden;
  background-color: #05516f;
  color: #48d9d9;
}

.container h1 {
  color: #fff;
  font-size: 42px;
  font-weight: normal;
}

.sectionName {
  margin-left: 50px;
  padding-top: 10px;
  width: max-content;
  margin-bottom: 50px;
  color: #fff;
  font-size: 18px;
}

@media screen and (max-width: 996px) {
  .sectionName {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .sectionName {
    margin-left: 10%;
  }
}

.content {
  margin: 0 10%;
  max-width: 80%;
  width: 100%;
}

.gridContainer {
  margin-top: 100px;
}

.gridRow {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr;
  margin-bottom: 30px;
  align-items: center;
}

.gridRow a:active {
  text-decoration: none;
}

@media screen and (max-width: 996px) {
  .gridRow {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.title {
  font-size: 32px;
}

.description {
  font-size: 20px;
  color: #fff;
}

.detailsButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 36px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-bottom: 1px solid #48d9d9;
  color: #ffffff;
  background-color: unset;
  padding: 0;
}

.detailsButton:hover {
  filter: brightness(80%);
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .container {
    padding-bottom: 1rem;
  }

  .container h1 {
    font-size: 32px !important;
  }

  .gridContainer {
    margin-top: 50px;
  }

  .sectionName {
    font-size: 12px !important;
  }

  .title {
    font-size: 22px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 10px;
    width: -webkit-fill-available;
  }

  .gridRowIcon {
    width: 16px;
  }

  .detailsButton {
    font-size: 12px;
  }
}
