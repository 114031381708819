.sectionName {
  margin-left: 50px;
  padding-top: 10px;
  width: max-content;
  margin-bottom: 50px;
  font-size: 18px;
}

@media screen and (max-width: 996px) {
  .sectionName {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .sectionName {
    margin-left: 10%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 4rem;
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
  color: #05516f;
}

.container h1 {
  font-size: 42px;
  font-weight: normal;
}

.content {
  margin: 0 10%;
  max-width: 80%;
  width: 100%;
}

.content p {
  font-size: 20px;
}

.list {
  margin-top: 50px;
}

.gridContainer {
  margin-top: 50px;
}

.gridRow {
  display: grid;
  grid-template-columns: 100px 1fr;
  margin-bottom: 30px;
  align-items: center;
}

@media screen and (max-width: 996px) {
  .gridRow {
    display: flex;
    gap: 10px;
  }
}

.description {
  font-size: 20px;
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .sectionName {
    font-size: 12px !important;
    margin-bottom: 0;
  }

  .container {
    padding-bottom: 1rem;
  }

  .container h1 {
    font-size: 32px !important;
  }

  .gridContainer {
    margin-top: 50px;
  }

  .gridContainer p {
    font-size: 16px;
  }

  .gridRowIcon {
    width: 16px;
  }

  .description {
    font-size: 14px;
    width: -webkit-fill-available;
  }
}
