.container {
  background-color: #05516f;
  color: #ffffff;
  padding: 1px 0 30px;
}

.container h1 {
  font-size: 72px;
  font-weight: normal;
}

.content a {
  display: block;
}

.content a:hover {
  text-decoration: none;
}

.positionDetails {
  margin: 0 10%;
}

.subtitle {
  font-size: 24px;
  margin: -20px 0 80px;
}

.applyNowButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 36px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-bottom: 1px solid #48d9d9;
  color: #ffffff;
  background-color: unset;
  padding: 0;
  margin-left: 50px;
}

.applyNowButton:hover {
  filter: brightness(80%);
}

@media screen and (max-width: 996px) {
  .applyNowButton {
    margin-top: 20px;
  }
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .container h1 {
    font-size: 42px !important;
  }

  .container p {
    font-size: 18px;
  }

  .anchorTags {
    margin-top: 50px;
  }

  .applyNowButton {
    margin-left: 10%;
    font-size: 12px;
    width: 120px;
  }
}
