.container {
  padding: 80px 10%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #05516f;
}

.flex {
  display: flex;
  gap: 100px;
}

.address {
  max-width: 400px;
  margin-bottom: 25px;
}

.socialLogos {
  display: flex;
  gap: 20px;
}

.socialLogos img {
  margin-top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 870px) {
  .socialLogos {
    margin-top: 20px;
  }
}
