.container {
  background-color: #05516f;
  color: #ffffff;
  padding: 1px 0 30px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 100px;
  margin: 0 10%;
}

.flex span {
  position: absolute;
  left: 0;
  margin-bottom: 60px;
  margin-left: 50px;
  font-size: 18px;
}

@media screen and (max-width: 1250px) {
  .flex span {
    display: none;
  }
}

.container h1 {
  font-size: 72px;
  font-weight: normal;
}

.subtitle {
  font-size: 24px;
}

.anchorTags {
  flex-wrap: wrap;
  display: flex;
  margin-top: 100px;
  margin-left: 50px;
  row-gap: 20px;
}

.anchorTags div {
  color: #48d9d9;
  margin-right: 40px;
  cursor: pointer;
}

.anchorTags div:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .anchorTags {
    margin-left: 10%;
  }
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .container h1 {
    font-size: 42px !important;
  }

  .container p {
    font-size: 18px;
  }

  .anchorTags {
    margin-top: 50px;
  }
}
