.container {
  padding-top: 30px;
  background-color: #05516f;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 50px;
}

@media screen and (max-width: 500px) {
  .content {
    margin: 0 7%;
  }
}

.content a {
  color: #05516f;
}

.links {
  color: #ffffff;
}

@media screen and (max-width: 996px) {
  .links {
    margin-top: 10px;
  }
}
