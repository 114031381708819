.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 50px 50px;
}

@media screen and (max-width: 996px) {
  .flex {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .flex {
    margin: 0 10%;
  }
}

.sectionName {
  padding-top: 10px;
  width: max-content;
  color: #fff;
  font-size: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 4rem;
  position: relative;
  overflow: hidden;
  background-color: #05516f;
  color: #48d9d9;
}

.container h1 {
  color: #fff;
  font-size: 42px;
  font-weight: normal;
}

.content {
  margin: 0 10%;
  max-width: 80%;
  width: 100%;
  color: #fff;
}

.content p {
  max-width: 1200px;
  margin-bottom: 30px;
  font-size: 20px;
}

.content a {
  display: block;
}

.content a:hover {
  text-decoration: none;
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 80px;
  margin-bottom: 40px;
}

.grid h1 {
  grid-row: 1;
  grid-column: 1;
  margin-bottom: 0;
}

.grid .paragraphs {
  grid-row: 2;
  grid-column: 2;
  margin-top: -20px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
}

@media screen and (max-width: 850px) {
  .grid {
    display: block;
  }

  .grid h1 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .grid .paragraphs {
    display: block;
  }
}

.applyNowButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 36px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-bottom: 1px solid #48d9d9;
  color: #ffffff;
  background-color: unset;
  padding: 0;
}

.applyNowButton:hover {
  filter: brightness(80%);
}

@media screen and (max-width: 996px) {
  .applyNowButton {
    /* margin-top: 20px; */
  }
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .container {
    padding-bottom: 1rem;
  }

  .container h1 {
    font-size: 32px !important;
  }

  .sectionName {
    font-size: 12px !important;
  }

  .container p {
    font-size: 16px;
  }

  .applyNowButton {
    font-size: 12px;
    width: 120px;
  }

  .applyNowButton img,
  .applyNowButton span {
    margin-top: 10px;
  }
}
